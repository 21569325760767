import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="text-center">
        <video autoPlay loop muted className="w-1/2 mx-auto mb-4">
          <source src="/logovideo.mp4" type="video/mp4" />
        </video>
        <h1 className="text-4xl font-bold mb-4 text-white">Bonus Stage</h1>
        <p className="text-xl mb-8 text-white">New Experience Coming Soon</p>
        {/* Discord Widget */}
        <div className="mb-8">
          <iframe 
            src="https://discord.com/widget?id=651408398507573248&theme=dark" 
            width="575" 
            height="500" 
            allowTransparency={true} 
            frameBorder="0" 
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            title="Discord Widget"
            className="mx-auto"
          ></iframe>
        </div>
        {/* Social media links commented out for now */}
        {/*
        <div className="flex justify-center space-x-4">
          <a href="https://twitter.com/your-twitter-handle" className="text-blue-500 hover:text-blue-600">Twitter</a>
          <a href="https://facebook.com/your-facebook-page" className="text-blue-500 hover:text-blue-600">Facebook</a>
          <a href="https://instagram.com/your-instagram-handle" className="text-blue-500 hover:text-blue-600">Instagram</a>
        </div>
        */}
      </div>
    </div>
  );
};

export default ComingSoon;
